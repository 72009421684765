body {
  box-sizing: border-box;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.6;
}

.hero-section {
  color: #fff;
  background: linear-gradient(#0009, #0009), url("herogif.68b9032f.gif") center / cover no-repeat;
}

.navbar {
  background: none;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.navbar-brand img {
  cursor: pointer;
}

.btn-demo {
  color: #000;
  cursor: pointer;
  background-color: #a4c5d8;
  border: none;
}

.btn-demo:hover {
  opacity: .8;
}

.btn-signin {
  cursor: pointer;
  background: none;
  padding: 1rem 2rem;
}

.btn-signin:hover {
  opacity: .8;
  color: #000;
  background-color: #fff;
}

.btn-request {
  cursor: pointer;
  border: none;
}

.btn-request:hover {
  opacity: .8;
}

.video-container video {
  z-index: 1;
  display: block;
  position: relative;
}

footer {
  color: #fff;
  background-image: url("footer-background.09e37f5b.jpg");
  background-position: center;
  background-size: cover;
  padding: 2rem 1rem;
  position: relative;
}

.footer-content {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-content img {
  cursor: pointer;
}

footer nav address a {
  color: #fff;
  text-decoration: none;
}
/*# sourceMappingURL=index.fa03b7f7.css.map */
