/* styles.css */

body {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  box-sizing: border-box;
  background-color: white;
}

.hero-section {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../images/herogif.gif") center center / cover no-repeat;
  color: white;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: transparent;
}

.navbar-brand img {
  cursor: pointer;
}

.btn-demo {
  background-color: #a4c5d8;
  border: none;
  color: black;
  cursor: pointer;
}

.btn-demo:hover {
  opacity: 0.8;
}

.btn-signin {
  background: none;
  cursor: pointer;
  padding: 1rem 2rem;
}
.btn-signin:hover {
  opacity: 0.8;
  background-color: white;
  color: black;
}

.btn-request {
  border: none;
  cursor: pointer;
}

.btn-request:hover {
  opacity: 0.8;
}

.video-container video {
  display: block;
  position: relative;
  z-index: 1;
}

footer {
  position: relative;
  background-image: url("../images/footer-background.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 2rem 1rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content img {
  cursor: pointer;
}

footer nav address a {
  color: white;
  text-decoration: none;
}
